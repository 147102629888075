@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(./fonts/Poppins-Bold.ttf) format('truetype');
}
.charte-details a{
  color: #0056B3;
  text-decoration: underline;
}
img{
  max-width: 100%;
}
.DayPicker .CalendarDay__selected,.DayPicker .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #04316b;
  border: 1px double #04316b;
}

.DayPicker  .CalendarDay__selected_span{
  background: green;
  border: 1px double green;
}

.skip-link {
  position: absolute;
  top: -100px;
  /* Styled to match the default WordPress screen reader text */
  background-color: #f1f1f1;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
  color: #21759b;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  height: auto;
  left: 6px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar */
  -webkit-transition: top .75s ease-out;
  transition: top .75s ease-out;
}

.skip-link:focus {
  color: #21759b;
  top: 7px;
  -webkit-transition: top 0s;
  transition: top 0s;
}

nav.main-menu a.current {
  font-weight: bold;
  text-decoration: underline;
}

nav a:hover, .evaluation_link:hover {
  /*Your code here, like:*/
  color: #04316b;
}

nav.menu-page a.current {
  font-weight: bold;
}

#breadcrumb {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: #04316b;
  width: 100%;
}

nav ol {
  list-style: none;
  text-underline-position: under;
}

nav ol a[href] {
  text-decoration: underline;
}

/* Display list items side by side */

nav ol li {
  display: inline;
  font-size: 1em;
  color: white;
  line-height: 0;
}

nav ol li a, nav ol li a:hover {
  color: white;
}

nav ol li span {
  vertical-align: middle;
}

/* Add a slash symbol (>) before/behind each list item */

/*nav ol li:not(:last-child):after{
  padding:0 0px;
  color: white;
  content: '>'; 
  speak: none;
  display: inline;
  vertical-align: middle;
  
}*/

.custom-control-label::before, .custom-control-label::after {
  top: 7px;
  width: 30px;
  height: 30px;
  left: -12px;
}

.mb-1, .my-1 {
 /* margin-bottom: -0.75rem !important;*/
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #04316b;
}

.custom-checkbox .custom-control-input~.custom-control-label::before {
  background-color: /*#5ac6c9;*/
  #72DEE1;
  border: 2px solid #04316b;
}

.custom-control-label {
/*  padding-top: 30px;*/
  padding-left: 30px;
  line-height: 44px;
}

label .infobulle {
  display: inline-block;
  /*margin-left: 0.5em;*/
  width: 44px;
  height: 44px;
  position: absolute;
}

label .infobulle svg{
  display: inline-block;
  position: relative;
  left: 10px;
}

ul {
  list-style-position: inside;
}

.App {
  text-align: center;
  font-family: Poppins-Regular;
  color: #04316b;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  position: -webkit-sticky !important;
  /* Safari */
  position: sticky !important;
  top: 0;
  flex: none;
}

.App header.MuiAppBar-root {
  /*max-width: 1526px;*/
  margin: auto;
  background-color: #fbd17b;
}

.App .MuiToolbar-regular, .programme-page .criterias{
  padding: 0;
  width: 1526px;
  margin: auto;
}

.programme-page .evaluation-details.evaluation{
  width: 100%;
  max-width: 100%;
}

@media screen and (max-width: 1600px) {
  .App .MuiToolbar-regular, .programme-page .evaluation .criterias{
    width: 90%;
  }
}

.banniere {
  background-color: #fbd17b;
}

html, body {
  margin: 0;
  padding: 0;
}

main {
  margin-top: 1em;
  flex: auto;
  scroll-margin-top: 6rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#menu-button {
  width: 3em;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MuiToolbar-root {
  background-color: #fbd17b;
  color: #01306b;
  justify-content: space-between;
}

/* "Page" Menu*/

.menu-page .list-group-item-action {
  border-bottom: 2px solid white;
  color: white !important;
  background-color: #04316b;
}
.menu-page .list-group-item-action:first-of-type {
  border-top: 2px solid white;
}

.menu-page .MuiListItem-button:focus, .menu-page .MuiListItem-button:hover, .menu-page .list-group-item-action:focus {
  color: #04316b !important;
  background-color: white;
}

.menu-page .MuiList-padding {
  padding-top: 0;
}

.App-link {
  color: #61dafb;
}

/*Boutons avec flèche*/

button.rbtn {
  padding: 0.6em 1em;
  border-radius: 30px;
  color: #fff;
  background-color: #208607;
  font-size: 1.1em;
  border: 0;
  cursor: pointer;
  margin: auto;
  margin-bottom: 1em;
  display: block;
  font-family: Poppins-Bold;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.rbtn > div {
  /*flex: 0 0 95%;
  width: 95%;*/
  margin-right: 0.5em;
}



.charte button.rbtn.beige {
  background-color: #fbd17b;
  color: #04316b;
}

button.rbtn.red {
  background-color: #d31829;
}

[data-icon] {
  float: right;
  display: block;
  margin-left: 10px;
  width: 10px;
  height: 10px;
}

[data-icon]:before {
  content: attr(data-icon)
}

/*button.rbtn div {display: block;}*/

.MuiAlert-action button {
  display: none;
}

.MuiAlert-filledWarning {
  background-color: #A86500 !important;
}

.MuiSelect-select, .MuiInputLabel-formControl {
  color: #04316b !important;
}

/*Profil*/

.card-column {
  display: inline-block;
  width: 15em;
  padding: 0 10px;
}

.card-column a:hover {
  text-decoration: none;
}

.cards:after {
  content: "";
  display: table;
  clear: both;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 8px 8px;
  text-align: center;
  background-color: #b7eff0;
  margin-bottom: 2em;
  color: #04316b;
  border: 2px solid #04316b;
  border-radius: 30px;
  padding-bottom: 0;
}

.card.active {
  background-color: #fbd17b;
  border: 5px solid #04316b;
  font-weight: bold;
}

.card img {
  width: 50%;
  margin: auto;
}

.card img.width-100 {
  width: 100%;
}


@media screen and (max-width: 413px) {
  .card.first p{
    min-height: 3em;
  }
}
/*.row{
  width: 50%;
  margin: auto;
}*/

.MuiOutlinedInput-root {
  border-radius: 25px !important;
  background-color: #b7eff0;
}

.reception .MuiFormControl-root {
  display: flex;
  margin: auto;
}

.MuiFormControl-root {
  width: 50%;
}

.profil .MuiFormControl-root{
  margin-bottom: 3em;
}
/*focus clavier*/

.card-column a:focus:not(.focus-visible)/*, .onglets button:focus:not(.focus-visible)*/ {
  outline: none !important;
  /*affiche le focus seulement s'il est reçu via le clavier : https://www.npmjs.com/package/focus-visible */
}

.MuiSelect-select:focus:not(.focus-visible) {
  background-color: transparent;
}

button:focus, a:focus, input:focus {
  outline: 3px solid black !important;
}

/*Charte*/

.charte a {
  color: #04316b;
  text-decoration: underline;
}

.charte p {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  width: 19em;
}

h3, #charte-title {
  margin-top: 1em;
}

h1, h3 {
  margin-bottom: 1em;
  font-family: Poppins-Bold;
}

p#rg1_label {
  margin-bottom: 2em;
}

.bold {
  font-family: Poppins-Bold;
}

h1:focus, h2:focus, h3:focus, p:focus {
  outline: none;
}

.mascotte-desktop, .mascotte-mobile {
  display: none;
}

.aucun-programme {
  background-color: #daf7f7;
  width: 25em;
  border-radius: 10px;
  margin: auto;
  padding: 2em;
  position: relative;
  top: 2em;
}

.aucun-programme p {
  margin-top: 1em;
}

.program a {
  color: #04316b;
  display: block;
}

.program-link {
  font-size: 2em;
}

.programme-details .row {
  margin-bottom: 2em;
}

.programme-details h1, .programme-details h2 {
  font-size: 1em;
}

.programme-details img.chaine {
  width: 7em;
}

.programme-details .program-desc h1 {
  margin-bottom: 0;
}

.program-thumbnail {
  position: relative;
  padding-top: 56.5%;
  /* 61 * 100 / 108; */
  overflow: hidden;
  
}

.program-thumbnail-no-thumbnail{
  background: #002f6d;
}

.program-thumbnail-error {
  background: red;
}

.program-thumbnail-loaded {
  background: none;
}

.program-thumbnail-loading {
  background: grey;
}

.program-thumbnail-no-thumbnail > div{
  background: #002f6d;
}

.program-thumbnail-no-thumbnail  > div img {
  width: 50%;
  position: relative;
  margin-bottom: 1em;
}

.program-thumbnail-no-thumbnail > div p {
  color: white;
  text-align: center;
}

.evaluation_link {
  vertical-align: middle;
  align-items: center;
  color: #04316b;
}

.evaluation_link:hover {
  text-decoration: none;
}

.evaluation_link img {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
}

.evaluation_link p {
  margin: 0;
}

.evaluation_link .evaluation_link_text {
  text-align: left;
}

.evaluation_link>span {
  margin: 0;
  font-size: 1.5em;
}

.evaluation_link.disabled {
  background-color: rgba(90, 198, 201, 0.6);
}

.evaluation_link.rounded {
  border-radius: 50px !important;
  border: none;
  color: white;
}

.evaluation_link.rounded.red {
  background-color: #d31829;
}

.evaluation_link.rounded.green {
  background-color: #227c0c;
}

.evaluation_link.rounded.gray {
  background-color: #575954;
}

.programme-details img {
  width: 100%;
}

.programme-details .program-img {
  flex: 0 0 20%;
  max-width: 20%;
  display: flex;
  align-items: flex-start;
  position: relative;
  justify-content: center;
  /*fix the streching on safari : https://stackoverflow.com/questions/57516373/image-stretching-in-flexbox-in-safari*/
}

.title_picto {
  width: 7em;
}

.logo_menu {
  width: 15em;
  /*padding: 1em;*/
  padding-left: 1em;
}

.logo_menu.center {
  margin: auto;
}

.logo_menu img {
  width: 100%;
}

.menu-page.back-button .list-group-item-action {
  color: #01306b !important;
  background-color: white;
  border-bottom: 1px solid #01306b;
  font-family: Poppins-Bold;
  text-align: left;
}

.menu-page .list-group-item-action {
  text-align: left;
}

.menu-page .list-group-item-action span {
  padding: 5px 0 5px 40px;
  background-repeat: no-repeat;
  background-size: 33px;
  margin-top: -5px;
  margin-bottom: -5px;
}

nav.menu-page .list-group-item-action span {
  padding: 1px 0 5px 45px;
  font-size: 1.5em;
}

.menu-programmes span {
  background-image: url(./img/menu/img-menu-list-programmes.png);
}

.menu-mon-historique span {
  background-image: url(./img/menu/img-menu-list-historique.png);
}

.menu-classement span {
  background-image: url(./img/menu/img-menu-list-ranking.png);
}

.menu-mon-profil span {
  background-image: url(./img/menu/img-menu-list-profil.png);
}

.menu-à-propos span {
  background-image: url(./img/menu/img-menu-list-about.png);
}

header.MuiPaper-elevation4 {
  box-shadow: none;
}

.menu-page.back-button .list-group-item-action span[aria-hidden] {
  margin-right: 1em;
  font-size: 2em;
  vertical-align: middle;
  padding: 5px 0 5px 0px;
}

p.float-right {
  margin-bottom: 3em;
}

/*mobile only*/

.program-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}


@media screen and (max-width: 800px) {

  
  .App {
    height: 100vh;
  }
  .float-right {
    float: none !important;
  }
  .App .MuiToolbar-regular {
    width: 90%;
  }
  .content, .addToHomeScreenPage, .charte-details {
    width: 90%;
    margin: auto;
  }
  #breadcrumb ol {
    padding: 0
  }
  .evaluation_link img {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .evaluation_link p {
    margin: 0;
  }
  .evaluation_link .evaluation_link_text {
    flex: 0 0 72%;
  }
  .logo_menu {
    margin: auto;
  }
  .MuiTypography-h1 {
    font-size: 2rem !important;
  }
  .MuiFormControl-root {
    width: 90%;
  }
  /*Profil*/
  .card-column {
    width: 50%;
    padding: 0 10px;
  }
  .card-column.center {
    clear: both;
    margin: auto;
  }
  .mobile-only {
    display: block;
  }
  .desktop-only, .desktop-only.rbtn {
    display: none;
  }
  .aucun-programme {
    width: 90%;
  }
  #filtres-programmes .col {
    margin-bottom: 2em;
  }
  .programmes {
    text-align: left;
  }
  .program {
    position: relative;
    overflow: hidden;
    margin: 0px auto;
    width: 100%;
    border-top: 1px solid #04316b;
    padding-left: 1em;
  }
  .program-desc p:first-child {
    margin-bottom: 0em;
  }
  .program-desc p {
    margin: 0;
  }
  .program-desc {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .program a {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
  }
  .program a>div {
    flex: 0 0 95%;
  }
  .program-thumbnail {
    width: 30%;
    padding-top: 0%;
    /* 0.3 * 56.5; */
    float: left;
    margin-top: 1em;
    margin-bottom: 1em;
  }
   .program-thumbnail-no-thumbnail > div img {
    width: 50%;
    margin: auto;
    display: block;
  }
  .program-thumbnail-no-thumbnail > div p {
    display: none;
  }
  .program a>div {
    height: 90%;
    display: flex;
    align-items: center;
  }
  .program .program-desc {
    float: left;
    width: 60%;
    margin-left: 3%;
  }
  .program::after {
    clear: both;
  }
  .evaluation_link {
    width: 100%;
  }
  .evaluation_link {
    border-top: 2px solid #04316b;
  }
  .evaluation_link.center, .evaluation_links .evaluation_link:last-of-type {
    border-bottom: 2px solid #04316b;
  }
  .evaluation_link.center {
    margin-bottom: 2em;
  }
  .evaluation_link.rounded {
    margin: auto;
    width: 100%;
    margin-bottom: 1em;
  }
  .evaluation {
    width: 90%;
    margin: auto;
  }
  .evaluation_link.rounded img {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .evaluation_link.rounded .evaluation_link_text {
    padding-left: 1em;
    flex: 0 0 70%;
  }
  .programme-details .program-desc {
    flex: 0 0 66.666666%;
    max-width: 66.666666%;
    text-align: left;
    padding-left: 1em;
  }
  .evaluation-title-mobile, .rating {
    width: 60%;
    margin: auto;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #04316b;
    display: flex;
  }
  .rating {
    width: 75%;
    padding-top: 1em;
    margin-top: 1em;
  }

  p.float-right{
    margin-bottom: 1em;
  }
  .evaluation-title-mobile h2, .rating p:first-of-type {
    font-size: 1em;
    margin: 0;
  }

  .rating p{
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .evaluation-title-mobile img, .img-rating {
    max-width: 30%;
  }

  

  .rating img{
    width: 100%;
  }
  .evaluation-title-mobile .evaluation-text {
    text-align: left;
    margin-top: 1em;
    padding-right: 10%;
  }
  .rating .rating-text {
    text-align: left;
    margin-top: 1em;
    margin-left: 1em;
  }
  hr {
    border: 0;
    border-top: 1px solid #04316b;
    margin-top: 0;
  }
  .col {
    flex-basis: unset;
    padding-left: 0;
  }
  .onglets {
    width: 100%;
    margin-bottom: 1em;
  }
  .onglets button {
    width: 50%;
    min-height: 4em;
  }
  .onglets button.active {
    min-height: 5em;
  }
  .programme-details img.chaine {
    margin-bottom: 1em;
  }
  .rbtn.send_vote_btn {
    margin-top: 1em;
    margin-left: auto;
  }
  .content {
    margin-bottom: 2em;
  }
  nav ol li {
    line-height: 1;
  }
  nav ol li a {
    text-align: left;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .program-thumbnail img {
    position: relative;
  }
  .programme-details img.chaine {
    width: 3em;
  }
  .programme-details .program-img {
    align-items: center;
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-family: Poppins-Bold;
  font-size: 1.2em;
}

nav li {
  float: left;
}

nav li a {
  display: inline-block;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  color: #04316b;
}

.onglets button {
  background-color: #b7eff0;
  border: 2px solid #04316b;
  color: #04316b;
  font-size: 1.2em;
  font-weight: bold;
}

.onglets button:not(:last-child) {
  border-right-width: 0;
}

/*.onglets button:not(:last-child):focus {
  border-right-width: 2px;
}*/

.onglets button.active {
  background-color: #fbd17b;
  position: relative;
  z-index: 1;
  font-weight: bold;
  border: 7px solid #04316b;
  height: 4em;
}

button.replay img{
  margin-top: -5px;
}
.onglets button img {
  width: auto;
  height: 40px;
}

#accueil-content .filters-toggle {
  float: right;
  margin-bottom: 1em;
}

#accueil-content h2 {
  clear: both;
}

.rating p:first-of-type {
  font-weight: bold;
}

.img-rating{
  border-radius: 50%;
}
.rating .img-rating.bon {
  background-color: green;
}

.rating .img-rating.mauvais {
  background-color: red;
}

.rating .img-rating.moyen {
  background-color: brown;
}

#rating_table{
  margin: auto;
  margin-top: 2em;
}

@media screen and (max-width: 400px) {
  .materiel .card p {
    min-height: 3em;
    line-height: 3em;
  }
  .materiel .card p.long-text {
    line-height: 1.5em;
  }
  .onglets button {
    font-size: 1em;
  }
}

@media screen and (min-width: 800px) {
  .programme-details .program-img {
    min-height: 10em;
  }

  #rating_table{
    flex: 1;
  
  }
  .program-thumbnail-no-thumbnail >div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  button[role=tab] img[alt=TV] {
    margin-top: -8px;
  }
  .DateRangePickerInput_arrow {
    margin: 1em;
  }
  h1#programmes-titre {
    text-align: left;
    float: left;
    margin-left: 0.5em;
  }
  .onglets {
    float: right;
  }
  .onglets button {
    width: 10em;
    height: 3em;
  }
  .menu-page.back-button .list-group-item-action div{
    max-width: 1526px;
    margin: auto;
  }

  .charte-details{
    max-width: 90%;
    margin: auto;
  }
  .charte button.rbtn {
    width: 18em;
  }
  .charte button.rbtn.beige {
    width: 15em;
  }
  .bottom-bar {
    background-color: #04316b;
    height: 5em;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1;
  }

  .evaluation-details .bottom-bar {
    position: relative;
    z-index: 0;
  }

  
  
  .content {
    max-height: 100%;
    padding-bottom: 10em;
  }

  .programme-page .evaluation-details .content{
    padding-bottom: 0;
  }

 

  
  .evaluation .content {
    /*margin-bottom: 2em;*/
  }
  .desktop-only {
    display: block;
  }
  .mobile-only, .mobile-only.rbtn {
    display: none;
  }
  .bottom-bar button.rbtn {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5%;
    border: 3px solid white;
  }
  .rating {
    float: right;
    flex-grow: 1;
    
  }
  .rating img{
    width: 100%;
  }

  .rating div.img-rating, .rating > img{
    width: 4em;
    text-align: right;
    float: right;
    
  }

  .rating div.img-rating {
    width: 4em;
    text-align: right;
    float: right;
    border-radius: 50%;
  }
  

  .rating p {
    clear: right;
    text-align: right;
    margin: 0;
  }
  .programmes {
    width: 100%;
    margin: auto;
  }
  .program {
    padding: 1em;
  }
  .program p {
    margin: 0;
    text-align: left;
  }
  .program p:first-child {
    margin-top: 1em;
  }
  .program-thumbnail {
    width: 100%;
  }
  .programme-page .programme-details, .programme-page .evaluation .criterias, .programme-page .evaluation_links, .programme-page .evaluation {
    max-width: 1526px;
    margin: auto;
  }
  .programme-details .program-desc {
    flex: 0 0 20%;
    max-width: 45%;
    text-align: left;
    padding-left: 2em;
  }
  .evaluation_link {
    width: 48%;
    float: left;
    margin-bottom: 1em;
  }

  .evaluation .evaluation_link{
    width: 31%;
  }
  .evaluation_links {
    margin-top: 4em;
  }
  .evaluation_link.first {
    margin-right: 4%;
  }

  .evaluation .evaluation_link{
    margin-right: 2%;
  }
  .evaluation_link.center {
    margin: auto;
    float: none;
    clear: both;
    margin-bottom: 2em;
  }
  .evaluation-text {
    text-align: right;
  }
  .evaluation-title {
    flex-grow: 1;
  }
  .programme-details .evaluation-title img {
    width: 3.5em;
    float: right;
  }
  
  .evaluation_link img {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .evaluation_link p {
    margin: 0;
    margin-left: 1em;
  }
  .evaluation_link .evaluation_link_text {
    flex: 0 0 84%;
  }
  .evaluation_link {
    border: 2px solid #04316b;
  }
  #breadcrumb ol {
    max-width: 1526px;
    margin: auto;
    padding: 0
  }
  .programme-details img.chaine {
    margin-bottom: 2em;
  }
}

.evaluation-details {
  text-align: left;
}

@media screen and (min-width: 1200px) {
  .mascotte-desktop {
    display: block;
    position: fixed;
    left: 12%;
    bottom: 10%;
    height: 70%;
  }
  .mascotte-evaluation {
    display: block;
    position: fixed;
    left: 15%;
    bottom: 0;
    height: 80%;
  }
  .mascotte-evaluation-mobile {
    display: none;
  }
  .evaluation-fin {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .evaluation-fin>div {
    margin-top: -5%;
  }
  .evaluation-fin button {
    margin-top: 4em;
  }
  .evaluation-fin {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(178deg, transparent 65%, #fbd17b calc(35% + 2px))
  }
}

.evaluation-fin h1 {
  margin-bottom: 0.5em;
}

@media screen and (min-width: 1388px) {
  .evaluation-details.sous_titrage .bottom-bar {
    position: fixed;
  }
}

@media screen and (min-width: 1600px) {
  .evaluation-fin>div {
    margin-top: -2%;
  }
}
@media screen and (min-width: 900px) {
  .evaluation-fin>div {
    margin-top: 0%;
  }
}
@media screen and (min-width: 1900px) {
  .evaluation-fin>div {
    margin-top: 0%;
  }
}

@media screen and (max-width: 1200px) {
  .mascotte-mobile {
    display: block;
    width: 15em;
    margin: auto;
  }
  .evaluation-fin {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .evaluation-fin>div {
    width: 90%;
    margin: auto;
  }
  .mascotte-evaluation {
    display: none;
  }
  .mascotte-evaluation-mobile {
    display: block;
    width: 50%;
    margin: auto;
  }
  .evaluation-fin {
    height: 100%;
    background-image: linear-gradient(176deg, white 80%, #fbd17b calc(20% + 2px))
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Text meant only for screen readers. */

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.classement-day, .historique-day {
  background: #01306b;
  color: #ffffff;
  text-align: left;
  padding: 10px 20px;
  margin: 0;
  font-size: 1em;
}

.classement h1, .historique h1 {
  margin-top: 1em;
  font-size: 1.6em;
}

.filters .col-md-6 {
  max-width: 400px;
}

.filters .filter {
  display: inline-block;
  text-align: center;
  min-width: 100px;
  padding: 2px 10px;
  border: 2px solid #04316b;
  background: #b7eff0;
  border-radius: 10px;
  line-height: 30px;
}

.filters .filter.selected {
  background: #fbd17b;
}

.filters .filter img {
  height: 40px;
}

.filters-date .filter {
  height: 48px;
  line-height: 36px;
}

.filters-date .filter img, .DateInput img {
  height: 30px;
  vertical-align: middle;
}

.filters-actions a {
  color: #04316b;
}

.filters-actions a:first-child {
  text-decoration: underline;
  font-size: smaller;
}

.filters-actions a:last-child {
  background: #fbd17b;
  padding: 8px 15px;
  border-radius: 30px;
  font-weight: bold;
}

.filters-toggle {
  position: relative;
  display: block;
  border: 2px solid #04316b;
  color: #04316b;
  background: #b7eff0 url(img/menu/img-filtre.png) no-repeat 4px 4px / 30px 30px;
  height: 40px;
  width: 70px;
  border-radius: 10px;
}

.filters-toggle:after {
  position: absolute;
  right: 10px;
  top: -4px;
  font-size: 2em;
  content: ">";
  transform: rotate(90deg);
}

.filters-toggle__open {
  background-color: #fbd17b;
}

.filters-toggle__open:after {
  content: ">";
  transform: rotate(-90deg);
}

.filters .DateInput {
  border: 2px solid #04316b;
  background: #b7eff0;
  border-radius: 10px;
  padding: 0.2em;
  width: auto;
}

.DateRangePickerInput_arrow {
  fill: #04316b;
}

.filters .DateInput_input {
  background: #b7eff0;
  border: none;
  color: #04316b;
  font-weight: bold;
  width: 11.7ch;
  font-size: 1em;
}

#filtres-programmes .SingleDatePickerInput__withBorder, .filters .DateRangePickerInput {
  border: none;
}

#filtres-programmes {
  clear: both;
}

.DateInput img {
  margin-top: -0.5em;
  margin-left: -0.5em;
}

.historique, .classement, #accueil-content {
  max-width: 1526px;
  margin: auto;
}

@media screen and (max-width: 400px) {
  .filters .filter {
    min-width: 90px;
  }
  .filters-date .filter {
    font-size: 0.8em;
    padding-left: 0;
    padding-right: 0;
  }
}


#rating_qual_lsf.moyen,#qual_lsf_text.moyen{
  color: #D14600;
}

#rating_qual_lsf.bon, #qual_lsf_text.bon{
  color: #006100;
}

#rating_qual_lsf.mauvais, #qual_lsf_text.mauvais{
  color: #AD0000;
}

#slider-block{
  max-width: 55em;
  margin-top: 4em;
  margin-bottom: 2em;
}

.PrivateValueLabel-label-92, .MuiSlider-thumb .MuiSlider-valueLabel span span{
  font-size: 1.5em;
}

#qual_lsf_text{
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}

 table{
  border-collapse: collapse;
 
  border: 1px solid #212529;
  width: 100%;
}
#rating_table tbody, #rating_table td, #rating_table tfoot, #rating_table th, #rating_table thead, #rating_table tr {
  border: 1px solid #212529;
}
#rating_table tr:nth-child(2n+1) {
  background-color: #ffffff;
}
#rating_table tr:nth-child(2n) {
  background-color: #B7EFF0;
}

#rating_table thead th {
  background-color: #FBD17B;
}

#rating_table th, #rating_table td {
  text-align: center;
  padding: 10px;
  font-size: 1.4em;
}



.programme-details td img{
  width: 1em;
  margin-bottom: 4px;
}